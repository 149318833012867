import React, { useState } from 'react';
import { graphql } from 'gatsby';
import moment from 'moment';
import Layout from '../containers/layout';
import ParagraphNews from '../components/ParagraphNews';
import WidgetCard from '../components/WidgetCard';
import HeaderSingleNews from '../components/HeaderSingleNews';
import FeedWithListCardsText from '../containers/FeedWithListCardsText';
import VisualComposer from '../components/VisualComposer';
import { useWordCounter } from '../hooks/word-counter';
import useMatchMedia from '../hooks/match-media';
import WidgetSocial from '../components/WidgetSocial';
import Download from '../components/Download';

const LocalNewsRegion = ({ data, pageContext }) => {
  const news = data.datoCmsLocalNewsRegion;
  const relatedNews = data.allDatoCmsLocalNewsRegion;
  const { slugArchive } = { slugArchive: data.datoCmsComitatiLocaliArchive.slug };
  const { caption, coverImage } = news;
  const { widgetCard } = data.datoCmsWidgetNewsLocali;

  let totalReadingTime = useWordCounter(news.paragraph);
  const allParagraphs = news.visualComposer.filter((vc) => vc['__typename'] === 'DatoCmsParagraph');
  allParagraphs.forEach((paragraph) => {
    totalReadingTime += useWordCounter(paragraph.content);
  });
  const [isMobile, setIsMobile] = useState(false);
  const mediaToMatch = '(max-width: 1365px)';
  useMatchMedia(setIsMobile, mediaToMatch);

  const haveVisualComposer = news.visualComposer && news.visualComposer.length > 0;
  const haveDocuments = news.documents.length > 0 && news.documents;

  return (
    <Layout
      seoMetaTags={news.seoMetaTags}
      pathname={pageContext.pathname}
      communicationBar={pageContext.communicationBar}
      pageType="Local News Page"
      canonical={pageContext.canonical}
    >
      <HeaderSingleNews
        title={news.title}
        label={news.comitato.title}
        archiveUrl={`/${slugArchive}/${news.comitato.slug}`}
        time={totalReadingTime}
      />
      <div className="news-wrapper">
        {widgetCard
        && isMobile
        && (
          <WidgetCard
            widgetCard={widgetCard}
          />
        )}
        <ParagraphNews
          content={news.paragraph}
          numCols="small"
          widgetCard={widgetCard}
          author={news.author}
          dropcap
          coverImage={coverImage}
          caption={caption}
          isLocalNews
          date={moment(news.publicationDate).format('DD/MM/YYYY')}
        />
        {
          haveVisualComposer && (
            <VisualComposer
              components={news.visualComposer}
            />
          )
        }
        {
          haveDocuments && (
            <Download
              label="Documenti disponibili"
              listDocument={news.documents}
              marginTop="default"
              marginBottom="default"
              numCols="small"
              labelButton="Download"
            />
          )
        }
      </div>
      <div className="news__widget-social">
        <WidgetSocial
          author={news.author}
          date={moment(news.publicationDate).format('DD/MM/YYYY')}
        />
      </div>
      <FeedWithListCardsText
        title="localNews"
        relatedNews={relatedNews.nodes.slice(0, 3)}
        slugArchive={slugArchive}
      />
    </Layout>
  );
};

export const LOCALNEWSREGION_QUERY = graphql`
    query LocalNewsRegionQuery($id: String!, $idComitato: String) {
        datoCmsLocalNewsRegion(id: {eq: $id}) {
            id
            title
            slug
            author
            caption
            migrated
            caption
            seoMetaTags {
                tags
            }
            coverImage {
                alt
                notes
                fluid(imgixParams: {fm: "jpg", auto: "compress", dpr: 2}) {
                    ...GatsbyDatoCmsFluid_noBase64
                }
                width
                height
            }
            visualComposer {
                ...CenteredNumbers
                ...Download
                ...FullImage
                ...MediumImage
                ...Numbers
                ...Quote
                ...Spacing
                ...SliderImagesGallery
                ...Paragraph
                ...VideoEmbed
            }
            seoMeta {
                title
                description
            }
            paragraph
            documents {
                title
                size
                format
                url
                originalId
            }
            publicationDate
            model {
                apiKey
            }
            comitato {
                title
                slug
            }
            coverImage {
                alt
                fluid(imgixParams: {fm: "jpg", auto: "compress", dpr: 2}) {
                    ...GatsbyDatoCmsFluid
                }
            }
        }
        allDatoCmsLocalNewsRegion (
            limit: 4,
            filter: {
                id: {
                    ne: $id
                },
                comitato: {
                    id: {
                        eq: $idComitato
                    }
                }
            },
            sort: {fields: publicationDate, order: DESC}
        ) {
            nodes {
                id
                title
                slug
                publicationDate
                comitato {
                    id
                    title
                    slug
                }
            }
        }
        datoCmsComitatiLocaliArchive {
            slug
            title
        }
        datoCmsWidgetNewsLocali {
            widgetCard {
                id
                title
                description
                colorTheme
                email
                phone
                iconImageModularContent {
                    ... on DatoCmsIcon {
                        id
                        iconType
                    }
                    ... on DatoCmsImage {
                        id
                        imageType {
                            alt
                            url
                        }
                    }
                }
                buttonModularContent {
                    labelButton {
                        value
                    }
                    url
                }
            }
        }
    }
`;

export default LocalNewsRegion;
